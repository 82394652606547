/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Toolov, nieograniczony czat',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  extCode: 'TPL',
  gtm: 'GTM-NXT9VJ4',
  gtmCountry: 'GTM-M2T8CMG',
  completeProfileInputs: ['phoneNumber'],
  id: 'ck5zfog47td990b621x6y4x1q',
  dvp4m: true,
  gsv: null,

  appLink: null,
  didomi: null,
};
